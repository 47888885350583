import {GenericModel} from '../shared/model/generic.model';

export class Dashboard extends GenericModel {

    public valorVendas:number;
	
	public valorCompras:number;
	
	public valorDuplicatasReceber:number;

	public valorDuplicatasPagar:number;
}
